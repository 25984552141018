import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import BlockContent from "../components/BlockContent"
import { FadeInTop } from "../components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubscribeSection from "../components/Subscribe"
import TeamSmiles from "../components/TeamSmiles"
import BlogArticleCard from "../components/BlogArticleCard"

const ArticlePage = ({ data }) => {
  const { title, content, publishedAt, dateRaw, mainImage } = data.sanityPost

  // merge related and latest articles so we have enough articles to
  // show if related articles < 3(limit)
  let mergeRelatedAndLatest = [
    ...data.relatedArticles.nodes,
    ...data.latestArticles.nodes,
  ]

  // // remove duplicates and cut down to 3
  let moreThinkinArticles = mergeRelatedAndLatest
    .reduce((acc, val) => {
      let isDuplicate = acc.find(article => article.title === val.title)
      if (isDuplicate) {
        return acc
      } else {
        return [...acc, val]
      }
    }, [])
    .slice(0, 3)

  return (
    <Layout>
      <Seo title={title} />
      <article className="px-4 mx-auto md:max-w-[540px] lg:max-w-[820px] pt-24 lg:pt-40 ">
        <h1 className="font-headings text-4xl lg:text-5xl leading-tight lg:leading-tight mb-6">
          {title}
        </h1>
        <div className="flex space-x-4 md:space-x-6 font-headings text-[#999] text-sm sm:text-base lg:text-lg">
          {/* <p className="mb-3 sm:mb-0">{author}</p>
          <p className="sm:block text-[#c4c4c4]">&#9679;</p> */}
          <time dateTime={dateRaw}>{publishedAt}</time>
        </div>
        <GatsbyImage
          image={mainImage?.asset?.gatsbyImageData}
          className="w-full my-12"
          imgClassName="w-full min-w-full h-auto"
        />
        <section className="c-rich-text">
          <BlockContent value={content} />
        </section>
      </article>
      <section className="c-container-full mt-24 lg:mt-48">
        <h2 className="border-t border-[#999] pt-3 md:pt-4 lg:pt-5 font-headings text-2xl md:text-3xl lg:text-4xl lg:leading-tight">
          More Thinkin'
        </h2>
        <div className="sm:flex flex-wrap sm:-mx-4 pt-8 sm:pt-12 2xl:pt-16">
          {moreThinkinArticles.map((article, i) => {
            return (
              <FadeInTop delay={(i % 3) * 0.25} threshold={0.15}>
                <BlogArticleCard data={article} />
              </FadeInTop>
            )
          })}
        </div>
      </section>
      <div className="pt-12 md:pt-14 lg:pt-0"></div>
      <TeamSmiles />
      <div className="bg-white pt-20 lg:pt-28 xl:pt-40">
        <SubscribeSection lightVariant={true} />
      </div>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query ($id: String!, $firstCategory: String!) {
    sanityPost(_id: { eq: $id }) {
      title
      content: _rawBody
      publishedAt(formatString: "D MMMM, YYYY")
      dateRaw: publishedAt(formatString: "YYYY-MM-D")
      mainImage {
        asset {
          gatsbyImageData
        }
      }
    }

    # query to get categories related to current post(excluding current post)
    # this is currently crappy as it only gets posts in the same category as the first category of current post
    # a better query(or algorithm) should get all posts from all of the post's categories, not just the first
    # an idea may be to add a related articles field to the post type
    relatedArticles: allSanityPost(
      filter: {
        category: { elemMatch: { title: { eq: $firstCategory } } }
        _id: { ne: $id }
      }
      sort: { fields: publishedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
        publishedAt(formatString: "D MMMM YYYY")
      }
    }

    # query to get latest posts excluding current post
    # this is to make the shown related articles >= 3 if the related articles above returns < 3 items
    latestArticles: allSanityPost(
      filter: { _id: { ne: $id } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        title
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
        publishedAt(formatString: "D MMMM YYYY")
      }
    }
  }
`
