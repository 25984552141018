import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "./animations"
import { useMediaQuery } from "../helpers/hooks"

// terrible name, I know...
const TeamSmiles = () => {
  let imgRef = useRef(null)
  let mediaQ = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    if (mediaQ) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: imgRef.current,
            start: "20% bottom",
            end: "bottom top",
            scrub: 0.5,
          },
        })
        .to(imgRef.current, {
          yPercent: -15,
        })
    }
  }, [mediaQ])

  return (
    <div className="c-container-full overflow-hidden h-32 md:h-40 lg:h-52 xl:h-72 transform translate-y-[50%]">
      <div
        className="bg-slate-50 h-full sm:h-auto overflow-y-hidden"
        ref={imgRef}
      >
        <StaticImage
          src="../assets/images/braudit-people-transparent.png"
          alt="braudit people photo"
          className="h-full"
          objectFit="cover"
          objectPosition="top"
          imgClassName="sm:h-auto"
        />
      </div>
    </div>
  )
}

export default TeamSmiles
